<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-26 10:14:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 10:52:25
 * @FilePath: \official_website\src\views\products\details\BloodPushing.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 全自动推片染色机 -->
<template>
  <div id="bloodPushing">
    <Header></Header>
    <div class="bloodPushing-conter">
      <div class="bloodPushing-img">
        <Breadcrumb
          :productType="productType"
          :productName="productName"
        ></Breadcrumb>
        <div class="illustrate-box">
          <div class="title wow animate__animated animate__fadeInUp">全自动推片染色机</div>
          <div class="series wow animate__animated animate__fadeInUp">CR-SMS120</div>
          <div class="line"></div>
          <ul class="keywords wow animate__animated animate__fadeInUp">
            <li>便捷</li>
            <li>高效</li>
            <li>优质</li>
            <li>价优</li>
          </ul>
        </div>
        <div class="product-img">
          <!-- <img src="@/assets/image/product/film-1.png" alt="" /> -->
        </div>
      </div>
      <div class="bloodPushing-description">
        <div class="parameter">
          <div class="parameter-item">
            <div class="parameter-box parameter-one"></div>
            <div class="parameter-box feature-one wow animate__animated animate__fadeInUp">
              <ul >
                <li>
                  <div class="theme">工作模式</div>
                  <div class="describe">
                    支持推片染色、单推片、单染色、打印玻片4种工作模式任意选择
                  </div>
                </li>
                <li>
                  <div class="theme">上机检测</div>
                  <div class="describe">
                    原始管直接上机，闭盖穿刺，一键式操作，无人值守
                  </div>
                </li>
                <li>
                  <div class="theme">流程可视化</div>
                  <div class="describe">
                    操作界面动态显示每一个样本处理流程，进座一目了然
                  </div>
                </li>
                <li>
                  <div class="theme">样本可溯源</div>
                  <div class="describe">
                    自动根据样本条码打印玻片条码，方便样本溯源。
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="parameter-item">
            <div class="parameter-box feature-two wow animate__animated animate__fadeInUp">
              <div class="title">高效</div>
              <p class="detail">推片: 每小时最快处理速度为120个样本</p>
              <p class="detail">梁色: 每小时最快处理速度为60个样本</p>
              <p class="detail">推片+染色: 每小时最快处理速度为80个样本</p>
            </div>
            <div class="parameter-box parameter-two"></div>
          </div>
          <div class="parameter-item">
            <div class="parameter-box parameter-three"></div>
            <div class="parameter-box feature-three wow animate__animated animate__fadeInUp">
              <div class="title">优质</div>
              <p class="detail">
                推片:自动断血液黏度，提供15种推片级别。仿生机械手推片推片更合理。推片结果更可靠。
              </p>
              <p class="detail">
                染色:标准化染色流程。去除人为因素影响，保证染色效果的稳定可靠
              </p>
            </div>
          </div>
          <div class="parameter-item">
            <div class="parameter-box parameter-text wow animate__animated animate__fadeInUp">
              <div class="title">价优</div>
              <div class="detail">
                设备、试剂、耗材由公司自主研发、自主生产，且支持第三方染液上机。成本更可控
              </div>
            </div>
            <div class="parameter-box parameter-four"></div>
          </div>
        </div>
      </div>

      <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "@/views/componets/Header.vue";
import Footer from "@/views/componets/Footer.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
import Breadcrumb from "@/views/componets/Breadcrumb.vue";
import api from "../../../api/api";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    RelatedProduct,
    Breadcrumb,
  },
  data() {
    return {
      productType: "产品",
      productName: "全自动推片染色机",
      relatProd: [
        {
          title: "骨髓/血液细胞形态学AI扫描分析系统",
          src: require("@/assets/image/product/blood-1.png"),
          path: "/product/marrow",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
     this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#bloodPushing {
  width: 100%;
  height: 100%;
  .bloodPushing-conter {
    padding-top: 5rem;
    .bloodPushing-img {
      position: relative;
      height: 50rem;
      background-image: url("../../../assets/image/product-detail/product-bg.png");
      background-size: cover;
      // img {
      //  position: absolute;
      //  left: 45%;
      //  top: 5%;
      // }
      .illustrate-box {
        position: absolute;
        left: 18%;
        top: 25%;
        width: 21.875rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 1rem;
        }
        .series {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 2.8125rem;
        }
        .line {
          width: 4.25rem;
          height: 3px;
          margin-bottom: 1.25rem;
          background: #177a73;
        }
        .keywords {
          display: flex;
          li {
            width: 22%;
            height: 2.1875rem;
            line-height: 2.1875rem;
            margin-right: 4%;
            color: #fff;
            background: #177a73;
            border-radius: 2.1875rem;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      .product-img {
        position: absolute;
        left: 45%;
        top: 12%;
        width: 50%;
        height: 75%;
        background-image: url("../../../assets/image/bloodPushing/pushing.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .bloodPushing-description {
      width: 100%;
      padding: 3.75rem 18%;
      .parameter {
        width: 100%;
        margin: 2rem 0 3rem;
        .parameter-item {
          display: flex;
          height: 24rem;
          margin-bottom: 2rem;
          // &:first-of-type {
          //   margin-bottom: 1.5rem;
          // }
          .parameter-box {
            width: 48%;
            margin-right: 4%;
            margin-bottom: 1.5rem;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }
          .parameter-one {
            background-image: url("../../../assets/image/bloodPushing/des-1.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 2rem;
          }
          .parameter-two {
            background-image: url("../../../assets/image/bloodPushing/des-2.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 2rem;
          }
          .parameter-three {
            background-image: url("../../../assets/image/bloodPushing/des-3.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 2rem;
          }
          .parameter-four {
            background-image: url("../../../assets/image/bloodPushing/des-4.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 2rem;
          }
          .parameter-text {
            padding: 7rem 3rem 5rem;
            .title {
              font-size: 1.8rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .detail {
              font-size: 1.3rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.5rem;
            }
          }
          .feature-one {
            padding: 1rem 3rem;
            ul {
              li {
                margin-bottom: 0.8rem;
                .theme {
                  font-size: 1.4rem;
                  font-family: "SourceHanSansSC-Medium";
                  margin-bottom: 0.5rem;
                }
                .describe {
                  font-size: 1rem;
                  font-family: "OPlusSans3-Regular";
                }
              }
            }
          }
          .feature-two {
            padding: 4rem 3rem;
            .title {
              font-size: 1.8rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .detail {
              font-size: 1.3rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.5rem;
              margin-bottom: 0.8rem;
            }
          }
          .feature-three {
            padding: 4rem 3rem;
            .title {
              font-size: 1.8rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .detail {
              font-size: 1.3rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.5rem;
              margin-bottom: 0.8rem;
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>